@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500;600&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Montserrat';
}

.container .col-md-4, .container .col-sm-12, .container .col-12
{
  padding-left: 3px;
  padding-right: 3px;
}

.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.navbar-logo img {
  border-style: none;
  box-sizing: content-box;
  max-width: 40%;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.nav-selected {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
  /*
  background-color: #fff;
  color: #242424;
  border-radius: 0;
  */
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    /*transform: translate(25%, 50%);*/
    flex-flow: column;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.footer-container {
  background-color: #242424;
  padding: 28px 0 28px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-link {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  
}

.website-rights {
  color: #fff;
  font-weight: 300;
}
  
.hero-container {
  /*background: url('https://b6ce6d99f980c70ae971-a171fe000e56965711f707fd82e4d474.ssl.cf2.rackcdn.com/nvpsoftware/public_html/wp-content/uploads/2020/11/Graph-1.jpg') center center/cover no-repeat;*/
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  text-align: center;
}

.hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

}

.banner_text {
  display: block;
    position: relative;
    text-align: center;
    color: white;
    top: 30%;
}

.banner_text p {
  font-size: 32px;
  font-weight: 500;
}
.show-container {
  padding: 60px 40px;
  max-width: 1366px;
  margin: 0 auto;
}

.show-preview {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
  margin: 0 auto 30px;
}

.show-preview p {
  font-family: 'Montserrat', sans-serif;
}

.show-box {
  position: relative;
  overflow: hidden;
  height: 305px;
}

.show-box img {
  position: absolute;
  top:50%;
  left:50%;
  -webkit-transform:translate(-50%,-50%);
          transform:translate(-50%,-50%);
  height:305px;
  object-fit:cover;
  margin: 10px 10px 10px 10px;
}

.show-box:hover img {
  cursor: zoom-in;
	opacity: .5;
}
.service-container {
  padding: 60px 40px;
  max-width: 1366px;
  margin: 0 auto;
}
.service-preview {
  text-align: center;
  margin: 0 auto 30px;
}
.service-container h1, h2, p{
  font-family: 'Montserrat', sans-serif;
}
.service-container h1{
  font-weight: 600;
}
.service-container h2{
  font-weight: 300;
  color: rgb(227, 6, 19);
}
.service-container p{
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
}
.box-header{
  margin: 0px auto 20px;
}
.service-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 1em;
}

@media screen and (max-width: 1200px) {
  .service-wrapper { 
      grid-template-columns: repeat(2, 1fr);
    }
  }
@media screen and (max-width: 767px) {
  .service-wrapper { 
    grid-template-columns: repeat(1, 1fr);
  }
}
.clients-container {
  padding: 60px 40px;
  max-width: 1366px;
  margin: 0 auto;
}
.clients-preview {
  margin: 0 auto 30px;
}
.clients-container h1,
h2,
p,
strong {
  font-family: "Montserrat", sans-serif;
}
.clients-container h1 {
  font-weight: 600;
  margin: 0 auto 10px;
}
.clients-container h2 {
  font-weight: 300;
  color: rgb(227, 6, 19);
}
.clients-container p {
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
}

.clients-box {
  text-align: center;
}

.clients-space {
  height: 70px;
}

.clients-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 1em;
}

@media screen and (max-width: 1200px) {
  .clients-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .clients-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.team-container {
  padding: 60px 40px;
  margin: 0 auto;
}

.team-preview {
  text-align: center;
  margin: 0 auto 30px;
}

.team-container h1,
h2,
p {
  font-family: "Montserrat", sans-serif;
}

.team-container h1 {
  font-weight: 600;
}

.team-container h2 {
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
  font-size: 16px;
}

.team-container p {
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
}

.team-box {
  text-align: center;
}

.team-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 1em;
}

@media screen and (max-width: 1200px) {
  .team-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .team-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .team-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}

.contact-container {
    padding: 60px 40px;
    max-width: 1366px;
    margin: 0 auto;
}
.contact-container h1, h2, p{
    font-family: 'Montserrat', sans-serif;
}
.contact-container h1{
    font-weight: 600;
    font-size: 25px;
    margin: 0 auto 20px;
}
.contact-container h2{
    font-weight: 300;
    color: rgb(227, 6, 19);
}
.contact-container p{
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.02em;
}
.contact-box{
    text-align: center;
}

.contact-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /*grid-template-columns: repeat(4, 1fr);*/
    grid-column-gap: 5%;
    grid-row-gap: 1em;
  }
  
  @media screen and (max-width: 1200px) {
    .contact-wrapper { 
        grid-template-columns: repeat(2, 1fr);
      }
    }
  @media screen and (max-width: 767px) {
    .contact-wrapper { 
      grid-template-columns: repeat(1, 1fr);
    }
  }

.contact-space {
  height: 50px;
}

.show-box:hover img {
  cursor: zoom-in;
	opacity: .5;
}
.prototypes-container {
    padding: 60px 40px;
    max-width: 1366px;
    margin: 0 auto;
}
.prototypes-preview {
    text-align: center;
    margin: 0 auto 30px;
}
.prototypes-container h1, h2, p{
    font-family: 'Montserrat', sans-serif;
}
.prototypes-container h1{
    font-weight: 600;
    margin: 0px auto 20px;
}
.prototypes-container h2{
    font-weight: 300;
    color: rgb(227, 6, 19);
}
.prototypes-container p{
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.02em;
    margin: 0px auto 30px;
}

.show-box:hover img {
    cursor: zoom-in;
      opacity: .5;
  }
.careers-container {
    padding: 60px 40px;
    margin: 0 auto;
}
