.team-container {
  padding: 60px 40px;
  margin: 0 auto;
}

.team-preview {
  text-align: center;
  margin: 0 auto 30px;
}

.team-container h1,
h2,
p {
  font-family: "Montserrat", sans-serif;
}

.team-container h1 {
  font-weight: 600;
}

.team-container h2 {
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
  font-size: 16px;
}

.team-container p {
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
}

.team-box {
  text-align: center;
}

.team-wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 1em;
}

@media screen and (max-width: 1200px) {
  .team-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .team-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .team-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
