@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;500;600&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Montserrat';
}

.container .col-md-4, .container .col-sm-12, .container .col-12
{
  padding-left: 3px;
  padding-right: 3px;
}
