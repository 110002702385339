.hero-container {
  /*background: url('https://b6ce6d99f980c70ae971-a171fe000e56965711f707fd82e4d474.ssl.cf2.rackcdn.com/nvpsoftware/public_html/wp-content/uploads/2020/11/Graph-1.jpg') center center/cover no-repeat;*/
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  text-align: center;
}

.hero-container > h1 {
  color: #fff;
  font-size: 70px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 32px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

}

.banner_text {
  display: block;
    position: relative;
    text-align: center;
    color: white;
    top: 30%;
}

.banner_text p {
  font-size: 32px;
  font-weight: 500;
}