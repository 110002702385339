.clients-container {
  padding: 60px 40px;
  max-width: 1366px;
  margin: 0 auto;
}
.clients-preview {
  margin: 0 auto 30px;
}
.clients-container h1,
h2,
p,
strong {
  font-family: "Montserrat", sans-serif;
}
.clients-container h1 {
  font-weight: 600;
  margin: 0 auto 10px;
}
.clients-container h2 {
  font-weight: 300;
  color: rgb(227, 6, 19);
}
.clients-container p {
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
}

.clients-box {
  text-align: center;
}

.clients-space {
  height: 70px;
}

.clients-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 1em;
}

@media screen and (max-width: 1200px) {
  .clients-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .clients-wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
