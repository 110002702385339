.service-container {
  padding: 60px 40px;
  max-width: 1366px;
  margin: 0 auto;
}
.service-preview {
  text-align: center;
  margin: 0 auto 30px;
}
.service-container h1, h2, p{
  font-family: 'Montserrat', sans-serif;
}
.service-container h1{
  font-weight: 600;
}
.service-container h2{
  font-weight: 300;
  color: rgb(227, 6, 19);
}
.service-container p{
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
}
.box-header{
  margin: 0px auto 20px;
}
.service-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 1em;
}

@media screen and (max-width: 1200px) {
  .service-wrapper { 
      grid-template-columns: repeat(2, 1fr);
    }
  }
@media screen and (max-width: 767px) {
  .service-wrapper { 
    grid-template-columns: repeat(1, 1fr);
  }
}