.contact-container {
    padding: 60px 40px;
    max-width: 1366px;
    margin: 0 auto;
}
.contact-container h1, h2, p{
    font-family: 'Montserrat', sans-serif;
}
.contact-container h1{
    font-weight: 600;
    font-size: 25px;
    margin: 0 auto 20px;
}
.contact-container h2{
    font-weight: 300;
    color: rgb(227, 6, 19);
}
.contact-container p{
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.02em;
}
.contact-box{
    text-align: center;
}

.contact-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /*grid-template-columns: repeat(4, 1fr);*/
    grid-column-gap: 5%;
    grid-row-gap: 1em;
  }
  
  @media screen and (max-width: 1200px) {
    .contact-wrapper { 
        grid-template-columns: repeat(2, 1fr);
      }
    }
  @media screen and (max-width: 767px) {
    .contact-wrapper { 
      grid-template-columns: repeat(1, 1fr);
    }
  }

.contact-space {
  height: 50px;
}

.show-box:hover img {
  cursor: zoom-in;
	opacity: .5;
}