.show-container {
  padding: 60px 40px;
  max-width: 1366px;
  margin: 0 auto;
}

.show-preview {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.75;
  letter-spacing: 0.02em;
  margin: 0 auto 30px;
}

.show-preview p {
  font-family: 'Montserrat', sans-serif;
}

.show-box {
  position: relative;
  overflow: hidden;
  height: 305px;
}

.show-box img {
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  height:305px;
  object-fit:cover;
  margin: 10px 10px 10px 10px;
}

.show-box:hover img {
  cursor: zoom-in;
	opacity: .5;
}