.footer-container {
  background-color: #242424;
  padding: 28px 0 28px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-link {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  
}

.website-rights {
  color: #fff;
  font-weight: 300;
}
  