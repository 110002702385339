.prototypes-container {
    padding: 60px 40px;
    max-width: 1366px;
    margin: 0 auto;
}
.prototypes-preview {
    text-align: center;
    margin: 0 auto 30px;
}
.prototypes-container h1, h2, p{
    font-family: 'Montserrat', sans-serif;
}
.prototypes-container h1{
    font-weight: 600;
    margin: 0px auto 20px;
}
.prototypes-container h2{
    font-weight: 300;
    color: rgb(227, 6, 19);
}
.prototypes-container p{
    font-weight: 300;
    line-height: 1.75;
    letter-spacing: 0.02em;
    margin: 0px auto 30px;
}

.show-box:hover img {
    cursor: zoom-in;
      opacity: .5;
  }